<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <Logo />
          <h2 class="brand-text text-primary ml-1">IMEI Checker</h2>
        </b-link>


 <div class="demo-spacing-0">
    <b-alert variant="warning" dismissible show >
      <div class="alert-body">
        <span> <center>
          We don't do unlocks, unlock iCloud or track stolen phones.
       <br><br>For any other information, 
       <a href="https://wa.me/3530852108011?text=Hi, I need help ">Contact us by WhatsApp</a>
           <br>
        </center>   </span>
      </div>
    </b-alert>
  </div>


        <h2>Login!</h2>

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="$refs.recaptcha.execute"
          >
            <!-- email -->
            <b-form-group label-for="email" label="Email or Username">
              <validation-provider
                #default="{ errors }"
                name="Email or Username"
                rules="required"
              >
                <b-form-input
                  id="emailOrUsername"
                  v-model="form.userkey"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <div class="d-flex justify-content-between">
              <label for="password">Password</label>
              <b-link :to="{ name: 'forget-pass' }">
                <small>Forgot Password?</small>
              </b-link>
            </div>
            <Password rules="required" v-model="form.password" label="" />

            <!-- submit button -->
            <div class="text-center">
              <Alert v-model="error" />
              <ReCaptcha ref="recaptcha" :submit="submit" />
              <b-button
                v-if="!loading"
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
              <b-spinner
                v-else
                variant="primary"
                type="grow"
                label="Spinning"
              />
            </div>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{ name: 'register' }">
            <span>Create an account</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">or sign in with</div>
        </div>

        <!-- social button -->
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button @click="loginWith('facebook')" variant="facebook">
            <div class="ico facebook-icon" />
          </b-button>
          <b-button @click="loginWith('google')" variant="google">
            <div class="ico google-icon" />
          </b-button>
          <!-- <b-button @click="loginWith('apple')" variant="github">
            <div class="ico apple-icon" />
          </b-button> -->
        </div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import Auth from "@/services/auth.service.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BAlert,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import Password from "@/components/util/Password.vue";
import Alert from "@/components/util/Alert.vue";
import ReCaptcha from "@/components/util/ReCaptcha.vue";

export default {
  components: {
     BAlert,
    ReCaptcha,
    Password,
    Alert,
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    Logo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    loading: false,
    form: {
      userkey: "",
      password: "",
    },
    status: "",
    error: null,
    // validation rules
    required,
    email,
  }),
  methods: {
    loginWith(provider) {
      Auth.loginWith(provider);
    },
    async submit(token) {
      this.loading = true;
      const success = await this.$refs.loginForm.validate();
      if (success) {
        try {
          await Auth.login({ ...this.form, recaptcha: token });
        } catch (e) {
          if (e.response.status === 403) {
            this.error = e.response.data;
          } else {
            this.error =
              e.response?.status === 422
                ? Object.values(e.response.data.errors)[0][0]
                : "Server error..";
          }
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scopped>
@import "@core/scss/vue/pages/page-auth.scss";
.ico {
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.google-icon {
  background-image: url("~@/assets/images/pages/login/google.svg");
}
.apple-icon {
  background-image: url("~@/assets/images/pages/login/apple.svg");
}
.facebook-icon {
  background-image: url("~@/assets/images/pages/login/facebook.svg");
}
</style>
